<template>
    <VNavigationDrawer clipped app :mini-variant="mini" permanent :expand-on-hover="mini">
        <VDivider/>
        <VList>
            <Link as="v-list-item" href="/" exact link>
                <VListItemIcon>
                    <HomeIcon class="tw-h-6 tw-w-6"/>
                </VListItemIcon>
                <VListItemTitle>
                    Übersicht
                </VListItemTitle>
            </Link>

            <VDivider/>

            <template v-for="item in items[role]">
                <Link as="v-list-item" :href="item.link.name" link v-if="item.link">
                    <VListItemIcon v-if="item.iconComponent">
                        <component :is="item.iconComponent"/>
                    </VListItemIcon>

                    <VListItemTitle v-if="item.title">{{ item.title }}</VListItemTitle>
                </Link>
                <VDivider v-else-if="item.divider"/>
            </template>

            <VDivider/>

            <VListItem @click="logout" link>
                <VListItemIcon>
                    <LogoutIcon/>
                </VListItemIcon>
                <VListItemTitle>Abmelden</VListItemTitle>
            </VListItem>
        </VList>
    </VNavigationDrawer>
</template>

<script setup lang="ts">
import AcademicCapIcon from '@/Components/Icons/AcademicCapIcon.vue';
import AdjustmentsIcon from '@/Components/Icons/AdjustmentsIcon.vue';
import ClipboardCheckIcon from '@/Components/Icons/ClipboardCheckIcon.vue';
import GiftIcon from '@/Components/Icons/GiftIcon.vue';
import HomeIcon from '@/Components/Icons/HomeIcon.vue';
import LockClosedIcon from '@/Components/Icons/LockClosedIcon.vue';
import LogoutIcon from '@/Components/Icons/LogoutIcon.vue';
import OfficeBuildingIcon from '@/Components/Icons/OfficeBuildingIcon.vue';
import PaperAirplane from '@/Components/Icons/PaperAirplane.vue';
import PuzzleIcon from '@/Components/Icons/PuzzleIcon.vue';
import SolidUsersIcon from '@/Components/Icons/SolidUsersIcon.vue';
import UsersIcon from '@/Components/Icons/UsersIcon.vue';
import {CompanyType} from '@/Models';

import {Link, router} from '@inertiajs/vue2';
import {useCurrentUser} from '@/Shared/Mixins';
import {computed} from 'vue';

defineProps<{
    mini: boolean,
}>();
const currentUser = useCurrentUser();

const logout = () => {
    router.post('/auth/logout');
    return;
}


const items = {
    'admin': [
        {
            iconComponent: OfficeBuildingIcon,
            link: {
                name: '/admin/company',
            },
            title: 'Companies',
        },
        {
            iconComponent: UsersIcon,
            link: {
                name: '/admin/user',
            },
            title: 'Users',
        },
        {
            divider: true,
        },
        {
            iconComponent: PaperAirplane,
            link: {
                name: '/shipments',
            },
            title: 'Sendungen',
        },
        {
            iconComponent: LockClosedIcon,
            link: {
                name: '/storage',
            },
            title: 'SecureStorage',
        },
        {
            iconComponent: GiftIcon,
            link: {
                name: '/admin/loyalty-program',
            },
            title: 'LoyaltyProgram',
        },
        {
            iconComponent: ClipboardCheckIcon,
            title: 'Orders',
            link: {
                name: '/digital-procurement',
            },
        },
        {
            divider: true,
        },
        {
            iconComponent: PuzzleIcon,
            link: {
                name: '/admin/jobs',
            },
            title: 'Internal Jobs',
        },
        {
            iconComponent: null,
            link: {
                name: '/admin/communication-log',
            },
            title: 'Communication Log',
        },
        {
            iconComponent: PaperAirplane,
            link: {
                name: '/admin/http-log',
            },
            title: 'Http Log',
        },
    ],
    'dentist': [
        {
            iconComponent: ClipboardCheckIcon,
            title: 'Aufträge',
            link: {
                name: '/digital-procurement',
            },
        },
        {
            iconComponent: PaperAirplane,
            link: {
                name: '/shipments',
            },
            title: 'Sendungen',
        },
        {
            iconComponent: SolidUsersIcon,
            link: {
                name: '/patient',
            },
            title: 'Patients',
        },
        {
            divider: true,
        },
        {
            iconComponent: AdjustmentsIcon,
            title: 'Meine Praxis',
            link: {
                name: '/company/profile',
            },
        },
        {
            iconComponent: GiftIcon,
            link: {
                name: '/company/reward',
            },
            title: 'Prämien',
        },
    ],
    'laboratory': [
        {
            iconComponent: PaperAirplane,
            link: {
                name: '/shipments',
            },
            title: 'Sendungen',
        },
        {
            divider: true,
        },
        {
            iconComponent: AdjustmentsIcon,
            link: {
                name: '/company/profile',
            },
            title: 'Mein Labor',
        },
        {
            iconComponent: AcademicCapIcon,
            link: {
                name: '/company/skill',
            },
            title: 'Kompetenzen',
        },
        {
            divider: true,
        },
        {
            iconComponent: LockClosedIcon,
            link: {
                name: '/storage',
            },
            title: 'SecureStorage',
        },
    ],
    'scan': [],
}

const role = computed(() => {
    switch (currentUser.company.type) {
        case CompanyType.ADMIN:
            return 'admin';
        case CompanyType.DENTIST:
            return 'dentist';
        case CompanyType.LABORATORY:
            return 'laboratory';
        case CompanyType.SCAN_SERVICE:
            return 'scan';
        default:
            return '';
    }
});
</script>
