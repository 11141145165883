<template>
    <InformationModal :show="showModalMessage" @close="seenModalMessage = true" :title="title" v-if="modal">
        {{ modal.message }}
    </InformationModal>
</template>

<script setup lang="ts">
import InformationModal from '@/Shared/Modal/InformationModal.vue';
import {computed, ref, watch} from 'vue';

const props = defineProps<{
    modal: any,
}>();

const seenModalMessage = ref(false);

watch(props, () => {
    if (props.modal) {
        seenModalMessage.value = false;
    }
})

const title = computed(() => {
    return props.modal.title ?? '';
});

const showModalMessage = computed(() => {
    return !!props.modal && props.modal.message && !seenModalMessage.value;
});
</script>
