<template>
    <VDialog @input="closeModal()" max-width="700px" :persistent="persistent" :value="show">
        <VCard>
            <VCardTitle>{{ title }}</VCardTitle>
            <VCardText>
                <slot/>
            </VCardText>
            <VDivider/>
            <VCardActions>
                <VSpacer/>
                <PrimaryTextButton @click="closeModal()">ok</PrimaryTextButton>
            </VCardActions>
        </VCard>
    </VDialog>
</template>

<script setup lang="ts">
import PrimaryTextButton from '@/Components/Buttons/PrimaryTextButton.vue';

interface Props {
    title?: string,
    persistent?: boolean,
    show?: boolean,
}

withDefaults(defineProps<Props>(), {
    persistent: true,
    show: false,
    title: '',
});

const emit = defineEmits(['close']);

const closeModal = () => {
    emit('close');
}
</script>
