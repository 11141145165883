<template>
    <div class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-text-center tw-space-x-2">
        &copy; {{ new Date().getFullYear() }} <strong>Art4Dent</strong>
        <Link href="/terms-of-service" v-if="currentUser !== null">AGB</Link>
        <a target="_blank" href="https://www.art4dent.com/impressum">Impressum</a>
        <a target="_blank" href="https://www.art4dent.com/datenschutz/">Datenschutz</a>
    </div>
</template>

<script setup lang="ts">
import {Link, usePage} from '@inertiajs/vue2';
import {computed} from 'vue';

const page = usePage();

const currentUser = computed(() => {
    return (page.props as any).auth?.user?.data;
});

</script>
