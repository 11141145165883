import {CreateElement, VNode} from 'vue';
import MainApp from '@/Layouts/MainApp.vue';
import AuthApp from '@/Layouts/AuthApp.vue';
import Aligner from '@/Layouts/Aligner.vue';
import DigitalProcurement from '@/Layouts/DigitalProcurement.vue';
import Wizard from '@/Layouts/Wizard.vue';

export function AppLayout(h: CreateElement, page: VNode) {
    return h(MainApp, [page]);
}

export function GuestLayout(h: CreateElement, page: VNode) {
    return h(AuthApp, [page]);
}

export function AlignerLayout(h: CreateElement, page: VNode) {
    return h(MainApp, [
        h(Aligner, [page]),
    ]);
}

export function DigitalProcurementLayout(h: CreateElement, page: VNode) {
    return h(MainApp, [
        h(DigitalProcurement, [page]),
    ]);
}

export function WizardLayout(h: CreateElement, page: VNode) {
    return h(MainApp, [
        h(Wizard, [page]),
    ]);
}
