import {DateTime} from 'luxon';

export interface Filters {
    fileSize: (value: number) => string;
    formatDay: (value: string) => string;
    formatTime: (value: string) => string;
}

export default {
    install: (Vue, options) => {
        Vue.prototype.$filters = {
            fileSize: (value: number) => {
                const units = ['B', 'kB', 'MB', 'GB'];
                let index = 0;
                while (value > 1024) {
                    value /= 1024;
                    index++;
                }

                return `${value.toFixed(2)} ${units[index]}`;
            },

            formatDay: (value: string) => {
                const d = DateTime.fromISO(value);
                return d.toLocaleString(DateTime.DATE_MED);
            },

            formatTime: (value: string) => {
                const d = DateTime.fromISO(value)
                return d.toLocaleString(DateTime.TIME_24_SIMPLE);
            },
        }
    },
}
