<template>
    <VAlert class="tw-mt-5" :icon="false" :type="type" v-if="alert" v-html="text"/>
</template>

<script setup lang="ts">
import {computed} from 'vue';

const props = defineProps<{
    alert: any,
}>();

const text = computed(() => {
    return props.alert instanceof Object ? props.alert.text : props.alert;
});

const type = computed(() => {
    return (props.alert instanceof Object && props.alert.type) ? props.alert.type : 'success';
});
</script>
