<template>
    <v-snackbar :color="color" v-model="show" v-if="content">
        {{ content }}
    </v-snackbar>
</template>

<script setup lang="ts">
import {computed, ref, watch} from 'vue';

const props = defineProps<{
    flash?: any,
}>();

const show = ref(false);

const content = computed(() => {
    if (!props.flash) {
        return '';
    }

    return props.flash.success ? props.flash.success : props.flash.failure;
});

const color = computed(() => {
    return props.flash?.success ? 'success' : 'error';
});

watch(props, () => {
    if (props.flash) {
        show.value = true;
    }
});

</script>
