/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import {ApolloClient, InMemoryCache} from 'apollo-boost';
import {createHttpLink} from 'apollo-link-http';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
// import App from '@/App.vue';
import Vue from 'vue';
import {createInertiaApp} from '@inertiajs/vue2';

import filters from '@/Plugins/Filters';
import svgJs from '@/Plugins/Svgjs';
import vuetify from '@/Plugins/Vuetify';
import {AlignerLayout, AppLayout, DigitalProcurementLayout, GuestLayout, WizardLayout} from '@/Layouts';
import VueApollo from 'vue-apollo';
import {provideApolloClient} from '@vue/apollo-composable';
import * as Sentry from '@sentry/vue';

import('./bootstrap');
import('./Filters');

import.meta.glob([
    '../images/**',
]);

Vue.config.productionTip = false;
Vue.use(svgJs);

Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/app\.art4dent\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createInertiaApp({
    resolve: async (name) => {
        const page = (await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')) as any).default;

        if (page.layout === undefined) {
            if (name.startsWith('Auth/')) {
                page.layout = GuestLayout;
            } else if (name.startsWith('Aligner/Wizard')) {
                page.layout = AlignerLayout;
            } else if (name.startsWith('DigitalProcurement/Wizard')) {
                page.layout = DigitalProcurementLayout;
            } else if (name.startsWith('Wizard')) {
                page.layout = WizardLayout;
            } else {
                page.layout = AppLayout;
            }
        }
        return page;
    },

    setup({el, App, props, plugin}) {
        const httpLink = createHttpLink({
            uri: '/graphql',
        });

        const apolloClient = new ApolloClient({
            link: httpLink,
            cache: new InMemoryCache(),
        });
        Vue.use(VueApollo);
        Vue.use(filters);

        Vue.use(plugin);
        const apolloProvider = new VueApollo({
            defaultClient: apolloClient,
            defaultOptions: {
                $loadingKey: 'loading',
            },
        })
        new Vue({
            setup(props, ctx) {
                provideApolloClient(apolloClient);
            },
            vuetify,
            apolloProvider,
            render: (h) => h(App, props),
        }).$mount(el)
    },

    title: (title: string) => `${title} - Art4Dent`,
});
