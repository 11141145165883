<template>
    <div class="tw-flex tw-min-h-full tw-flex-col">
        <ol class="tw-overflow-hidden tw-text-sm tw-text-gray-500 tw-border tw-border-gray-400 tw-grid tw-grid-cols-1 tw-divide-x tw-divide-gray-300"
            :class="`sm:tw-grid-cols-${Math.min(sites.length, 12)}`">
            <li v-for="(item, index) in sites" :key="item.target" class="tw-relative tw-flex tw-items-center tw-justify-center tw-p-4">
                <span v-if="index !== 0"
                      class="tw-absolute tw-hidden tw-w-8 tw-h-8 tw-bg-white tw-border tw-border-b-0 tw-border-l-0 tw-border-gray-300 tw-rotate-45 -tw-translate-y-1/2 sm:tw-block -tw-left-4 tw-top-1/2"/>

                <p class="tw-leading-none tw-m-0">
                    <strong class="tw-block tw-font-medium" :class="isActive(item.target) ? 'tw-text-gray-800 tw-font-bold' : 'tw-text-a4d'">{{ item.label }}</strong>
                </p>
            </li>
        </ol>

        <div class="tw-flex tw-flex-col tw-justify-between tw-flex-1 mr-5 tw-mt-2">
            <slot class="flex"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {usePage} from '@inertiajs/vue2';

const page = usePage();

const sites = [
    {target: '/digital-procurement/wizard/patient', label: 'Patient'},
    {target: '/digital-procurement/wizard/impression-type', label: 'Abformmethode'},
    {target: '/digital-procurement/wizard/teeth-map', label: 'Zahnbogen'},
    {target: '/digital-procurement/wizard/tooth-color', label: 'Farbe'},
    {target: '/digital-procurement/wizard/denture-type', label: 'Zahnersatz'},
    {target: '/digital-procurement/wizard/denture-material', label: 'Material'},
    {target: '/digital-procurement/wizard/summary', label: 'Zusammenfassung'},
    {target: '/digital-procurement/wizard/desired-date', label: 'Wunschtermin'},
];

const isActive = (route: string) => {
    let index: number | undefined = page.url.indexOf('?');
    if (index === -1) {
        index = undefined;
    }
    return page.url.substring(0, index) === route;
}
</script>
