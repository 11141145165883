<template>
    <VApp v-cloak>
        <Notification :flash="$page.props.flash"/>

        <VAppBar app color="primary" height="60px" clipped-left>
            <VBtn text icon color="white" @click="leftButtonAction">
                <MenuIcon/>
            </VBtn>
            <div class="tw-text-4xl tw-text-gray-800 tw-ml-2">
                <Link class="tw-text-gray-700" href="/">
                    ART<span class="tw-text-white">4</span>DENT
                </Link>
            </div>
            <v-spacer/>
            <div v-if="currentUser.isAdmin && currentUser.company.type !== CompanyType.ADMIN">
                <button class="tw-text-white" @click.stop="leaveImpersonation">Leave impersonation</button>
            </div>
            <div class="tw-flex">
                <Notifcations/>
                <VAvatar>
                    <v-img :src="currentUser.avatar_url"></v-img>
                </VAvatar>
                <div class="tw-flex tw-flex-col tw-ml-4 tw-text-white tw-justify-center">
                    <span>{{ currentUser.first_name }} {{ currentUser.last_name }}</span>
                    <span class="tw-text-xs tw-text-gray-100">{{ companyName }}</span>
                </div>
            </div>
        </VAppBar>
        <CommonDrawer :mini="mini"/>
        <VMain>
            <Alert :alert="$page.props.alert"/>
            <div class="tw-flex tw-justify-center tw-p-3 tw-min-h-full">
                <div class="tw-flex-1 tw-pb-4">
                    <slot/>
                </div>
                <AppFooter/>
            </div>
        </VMain>

        <PopupNotification :modal="$page.props.modal"/>
    </VApp>
</template>

<script setup lang="ts">
import Alert from '@/Components/Alert.vue';
import CommonDrawer from '@/Components/Common/CommonDrawer.vue';
import Notification from '@/Components/Common/Notification.vue';
import PopupNotification from '@/Components/Common/PopupNotification.vue';
import MenuIcon from '@/Components/Icons/MenuIcon.vue';
import Notifcations from '@/Components/Notifcations.vue';
import {CompanyType} from '@/Models';
import AppFooter from '@/Shared/AppFooter.vue';
import {useCurrentUser} from '@/Shared/Mixins';
import {Link, router} from '@inertiajs/vue2';
import {computed, ref} from 'vue';

const currentUser = useCurrentUser();

const mini = ref(false);

// VUEX
const role = computed((): string => {
    switch (currentUser.company.type) {
        case CompanyType.ADMIN:
            return 'admin';
        case CompanyType.DENTIST:
            return 'dentist';
        case CompanyType.LABORATORY:
            return 'laboratory';
        default:
            return '';
    }
});

const companyName = computed((): string => {
    if (currentUser.company) {
        return currentUser.company.name;
    }

    return 'Administrator';
});

const header = computed(() => {
    //     return this.$route.meta.title;
    return '';
});

const leaveImpersonation = () => {
    router.delete(`/admin/company/${currentUser.company.id}/impersonate`);
}

const leftButtonAction = () => {
    // if (this.$route.meta.backRoute) {
    //     this.$router.push({name: this.$route.meta.backRoute});
    // } else {
    mini.value = !mini.value;
    // }
};
</script>
