<template>
    <div class="tw-flex tw-min-h-full">
        <div class="tw-flex tw-flex-col tw-justify-between tw-flex-1 mr-5">
            <slot class="flex"/>
        </div>
        <div class="tw-h-full">
            <img alt="Art4Dent Logo" class="mb-4" width="300px" :src="require('@/Assets/Logo.png')"/>

            <v-timeline align-top dense>
                <v-timeline-item small :color="isActive(step.slug) ? 'grey': 'primary'" v-for="step in wizard.steps" :key="step.slug">
                    <span :class="isActive(step.slug) ? 'tw-text-gray-800' : 'tw-text-a4d'">{{ step.title }}</span>
                </v-timeline-item>
            </v-timeline>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {usePage} from '@inertiajs/vue2';

const page = usePage();

const wizard = computed(() => {
    return (page.props as any)._wizard;
});

const isActive = (slug: string) => {
    return wizard.value.steps.find((step: any) => step.slug === slug).active;
}
</script>
