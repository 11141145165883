import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import * as components from 'vuetify/lib/components';
import de from 'vuetify/src/locale/de';

Vue.use(Vuetify, {
    components: {
        ...components,
    },
});

export default new Vuetify({
    components: {
        ...components,
    },
    lang: {
        locales: {de},
        current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: '#008fb0',
            },
        },
    },
});
