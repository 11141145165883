<template>
    <v-btn @click="click" color="primary" text>
        <slot/>
    </v-btn>
</template>

<script setup lang="ts">

const emit = defineEmits(['click']);
const click = (e: MouseEvent) => {
    emit('click', e);
}
</script>
